import { useState } from "react";
import { Heading, Modal } from "@sunwing/components";
import { Button as ButtonComponent } from "@sunwing/components/dist/Button";
import { RCL } from "../../components/RCL";
import { ParagraphConnector } from "../../components/Paragraph";
import config from "../../app/config";

const PlatinumClubCtas = ({locale, platinumClubParagraph, platinumClubFAQSParagraph, toggleFAQModal,isFAQModalOpen}) => {
  const [isOpen, setIsOpen] = useState(false);


  function toggleModal() {
    setIsOpen(!isOpen);
  }

    return (
      <>
        <div className="star-balance__cta-btns">
        <ButtonComponent
          outline
          size={config.isWestJetMode ? "": "lg"}
          icon="arrow-right"
          iconPlacement="right"
          onClick={toggleModal}
        >
          <RCL searchKey="how-platinum-club-rewards-work" />
        </ButtonComponent>
        <ButtonComponent
         outline
         size={config.isWestJetMode ? "": "lg"}
         icon="arrow-right"
         iconPlacement="right"
         onClick={toggleFAQModal}
       >
         <RCL searchKey="platinum-club-rewards-faqs" />
        </ButtonComponent>
        <ButtonComponent
          outline
          size={config.isWestJetMode ? "": "lg"}
          icon="arrow-right"
          iconPlacement="right"
          to={
            config.isWestJetMode ? 
              (locale === "fr"
                ? "https://assets.sunwingtravelgroup.com/image/upload/v1715623094/sunwing-prod/Web%20Replatform%20Project/Agent%20Portal/%20Groups%20Resources/Platinum/Platinum%20Club%20T-C%20VWQ%20FR.pdf"
                : "https://assets.sunwingtravelgroup.com/image/upload/v1715623094/sunwing-prod/Web%20Replatform%20Project/Agent%20Portal/%20Groups%20Resources/Platinum/Platinum%20Club%20T-C%20VWQ%20EN.pdf") :
                (locale === "fr"
                ? "https://assets.sunwingtravelgroup.com/image/upload/v1715622881/sunwing-prod/Web%20Replatform%20Project/Agent%20Portal/%20Groups%20Resources/Platinum/Platinum%20Club%20T-C%20SWG%20FR.pdf"
                : "https://assets.sunwingtravelgroup.com/image/upload/v1715622881/sunwing-prod/Web%20Replatform%20Project/Agent%20Portal/%20Groups%20Resources/Platinum/Platinum%20Club%20T-C%20SWG%20EN.pdf") 
          }
          target="_blank"
        >
          <RCL searchKey="platinum-club-rewards-terms-and-conditions" />
        </ButtonComponent>
        </div>
        <Modal
          isOpen={isOpen}
          onClose={toggleModal}
          labels={{ close: "Close", content: "" }}
          className="star-balance__modal"
          simple
          top={false}
        >
          <Modal.Content>
            <Heading size="h2" as="p" titleAlignment="left">
              <RCL searchKey="howPointsAreAwarded" />
            </Heading>
            <div>
                <ParagraphConnector
                  data={platinumClubParagraph}
                  locale={locale === "fr" ? locale : "en-CA"}
                />
            </div>
          </Modal.Content>
        </Modal>
        <Modal
          isOpen = {isFAQModalOpen}
          onClose = {toggleFAQModal}
          labels={{ close: "Close", content: "" }}
          className="star-balance__modal"
          simple
          top={false}
        >  
          <Modal.Content>
            <Heading size="h2" as="p" titleAlignment="left">
              <RCL searchKey="platinum-club-rewards-faqs" />
            </Heading>
            <div>
                <ParagraphConnector
                  data={platinumClubFAQSParagraph}
                  locale={locale === "fr" ? locale : "en-CA"}
                />
            </div>
          </Modal.Content>
        </Modal>
          </>
    )
}

export default PlatinumClubCtas;
export { PlatinumClubCtas };